import React from 'react';
import './Welcome.css';

function Welcome() {
    return (
        <div className="welcome">
            <text id="greeting"><span>Hi! My name is</span></text>
            <h1 id="nameline">Julian Coronado.</h1>
        </div>
    );
}

export default Welcome;