import React from 'react';
import './Links.css';

function Links() {
	return (
		<div className="links">
			<h2>Contact</h2>
			<text><span><a target="_blank" rel="noopener noreferrer" href="mailto:contact@jcoronado.dev">Email</a></span></text>
			<text><span><a target="_blank" rel="noopener noreferrer" href="/juliancoronado_resume.pdf" download>Resume</a></span></text>
			<text><span><a target="_blank" rel="noopener noreferrer" href="https://github.com/juliancoronado">GitHub</a></span></text>
			<text><span><a target="_blank" rel="noopener noreferrer" href="https://linkedin.com/in/juliancoronado">LinkedIn</a></span></text>
			<text><span><a target="_blank" rel="noopener noreferrer" href="https://threads.net/@jcoronado.dev">Threads</a></span></text>
			<br></br>
		</div>
	);
}

export default Links;