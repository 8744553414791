import React from 'react';
import './ProjectSection.css';
import Project from '../Project/Project';

function ProjectSection() {
	return (
		<div className="projectsection">
			<h2>Apps</h2>

			< Project
				title="Cafe Buddy - Android and iOS"
				description="A mobile app to log your favorite orders from restaurants, cafes, and more."
				link="https://cafebuddy.app" />

			< Project
				title="Minimal Bitcoin Widget - Android"
				description="A minimal home-screen widget that displays the current price of Bitcoin."
				link="https://github.com/juliancoronado/MinimalBitcoinWidget" />

		</div>
	);
}

export default ProjectSection;