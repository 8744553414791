import React from 'react';
import './AboutMe.css';

function AboutMe() {
    return (
        <div className="aboutme">
            <p>
                I'm a Mobile Developer creating beautiful, helpful apps.
                <br></br>
                I use&nbsp;
                <a href="https://flutter.dev">Flutter</a>
                &nbsp;and&nbsp;
                <a href="https://firebase.google.com">Firebase</a>
                &nbsp;to build for Android and iOS.
                <br></br>
            </p>
        </div>
    );
}

export default AboutMe;